exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-beneficios-y-afiliacion-tsx": () => import("./../../../src/pages/beneficios-y-afiliacion.tsx" /* webpackChunkName: "component---src-pages-beneficios-y-afiliacion-tsx" */),
  "component---src-pages-congreso-tsx": () => import("./../../../src/pages/congreso.tsx" /* webpackChunkName: "component---src-pages-congreso-tsx" */),
  "component---src-pages-contactenos-tsx": () => import("./../../../src/pages/contactenos.tsx" /* webpackChunkName: "component---src-pages-contactenos-tsx" */),
  "component---src-pages-eventos-tsx": () => import("./../../../src/pages/eventos.tsx" /* webpackChunkName: "component---src-pages-eventos-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-historia-tsx": () => import("./../../../src/pages/historia.tsx" /* webpackChunkName: "component---src-pages-historia-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-junta-directiva-tsx": () => import("./../../../src/pages/junta-directiva.tsx" /* webpackChunkName: "component---src-pages-junta-directiva-tsx" */),
  "component---src-pages-quienes-somos-tsx": () => import("./../../../src/pages/quienes-somos.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-tsx" */),
  "component---src-pages-reporte-imagenologico-tsx": () => import("./../../../src/pages/reporte-imagenologico.tsx" /* webpackChunkName: "component---src-pages-reporte-imagenologico-tsx" */),
  "component---src-pages-video-posters-tsx": () => import("./../../../src/pages/video-posters.tsx" /* webpackChunkName: "component---src-pages-video-posters-tsx" */),
  "component---src-template-blog-post-tsx": () => import("./../../../src/template/BlogPost.tsx" /* webpackChunkName: "component---src-template-blog-post-tsx" */),
  "component---src-template-congress-gallery-tsx": () => import("./../../../src/template/CongressGallery.tsx" /* webpackChunkName: "component---src-template-congress-gallery-tsx" */),
  "component---src-template-video-poster-tsx": () => import("./../../../src/template/VideoPoster.tsx" /* webpackChunkName: "component---src-template-video-poster-tsx" */)
}

